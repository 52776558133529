<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("select_item_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('select_item_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('select_item_page.content')" v-html="getModuleConfig('select_item_page.content')"></div>

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div class="field">
          <!-- <label class="field__label"> 我的代理商 </label> -->
          <div class="row">
            <div
              class="col-12 px-2"
              v-for="(option, idx) in options"
              :key="idx"
            >
              <SharedRadio
                v-model="proxy"
                :id="`proxy-${idx}`"
                :value="option.value"
                name="proxy"
              >
                {{ option.text }}
              </SharedRadio>
            </div>
          </div>
        </div>

        <small>{{ getModuleConfig("select_item_page.form_memo") }}</small>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('select_item_page.button_text') ||
        getModuleConfig('select_item_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton
        :disabled="!proxy"
        @click="confirmSelectItem"
        class="s-btn-bg-primary mb-2"
        >{{ getModuleConfig("select_item_page.button_text") }}</SharedButton
      >

      <MemberButton
        :button-style='`s-btn-outline-primary`'
        :button-text="getModuleConfig('select_item_page.cancel_button_text')"
        :button-url="getModuleConfig('select_item_page.cancel_button_url')"
        :type="getModuleConfig('select_item_page.cancel_button_action')"
      ></MemberButton>

      <!-- <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("select_item_page.cancel_button_text")
      }}</SharedButton> -->
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import couponMixin from "@/mixins/liff/coupon";
import SharedRadio from "@/components/DynamicForm/Radio.vue";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapActions } from 'vuex';

export default {
  mixins: [couponMixin],
  components: { Loading, SharedButton, SharedRadio, MemberButton },
  data() {
    return {
      showLoading: false,
      options: [],
      proxy: null,
    };
  },

  computed: {},
  async mounted() {
    await this.fetchCoupon();
  },

  methods: {
    ...mapActions('projAbbottAnd', ['setRedeemData']),
    confirmSelectItem() {
      this.setRedeemData({
        coupon_id: this.$route.params.id,
        item_id: this.proxy,
        item_name: this.options.find((item) => item.value === this.proxy).text,
      });
      this.$router.push({
        name: "LiffProjAbbottAndCouponRedeemConfirm",
      });
    },
    async fetchCoupon() {
      await abbottAndApi.getCouponDetail(
        this.$route.params.orgCode,
        {
          coupon_id: this.$route.params.id
        }).then((res) => {
          this.options = res.data.data.items.map((item) => {
            return {
              text: item.name,
              value: String(item.id),
            }
          })
          if (this.options.length === 0) {
            this.confirmSelectItem()
          }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  &__label {
    font-size: 20px;
    margin-bottom: 8px;
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
  }

  &__desc {
    font-size: 16px !important;
  }
}

.row {
  margin: 0 -0.5rem;
}


</style>
